// Base colors

$color-green: #008f4f;
$color-green-light: #d7efd6;

$color-black: #16191d;
$color-black-2: #69707f;
$color-black-3: #8798ad;
$color-blue-dark: #1b2b3a;

$color-gray: rgb(225, 225, 240);
$color-gray-1: rgba(49, 62, 74, 1);
$color-gray-2: #eef3f5;

$color-blue: #008dea;
$color-blue-20: #dfeefa;
$color-blue-light: #f4f8fc;

$color-red: #d63649;
$color-red-light: #f7d7db;

$color-orange: #dc4e44;
$color-orange-dark: #b92225;
$color-orange-light: rgba(220, 78, 68, 0.08);

$color-yellow: #f7c137;
$color-yellow-light: #fef3d7;
