iframe {
    position: absolute !important;
}

.dropdown-toggle::after {
    display: none !important;
}
/*added for avatar*/
.user-avatar-header {
    width: 30px;
    height: 30px;
    border-radius: 20px;
}
/*end of avatar*/
