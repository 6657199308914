@import '../../assets/styles/mixins';

body {
    font-size: 0.875rem;
    min-width: 768px;
    background-color: #f4f8fc;
}

.custom-card-body {
    padding: 0rem;
}

.custom-card {
    border: 0px solid rgba(0, 0, 0, 0.125);
}

.table {
    margin: 0px;
}

.nav-link {
    font-size: 16px;
}

.navbar-expand .navbar-nav .nav-link {
    padding-left: 1rem;
}

.question-text {
    font-weight: 700;
}

.que-ans-list {
    position: relative;
    display: block;
    padding: 0.25rem 1.25rem;
}

.que-ans {
    border: none;
    padding: 0.2rem 1.25rem;
}

.que-ans-card-body {
    padding: 0.15rem !important;
}
